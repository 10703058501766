import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component<GatsbyProps> {
    render() {
        const pageName = 'Despre noi'

        return (
            <Layout location={this.props.location}>
                <main className="container mx-auto p-4">
                    <h1>{pageName}</h1>
                    <p>
                        Am luat fiinta in anul 1994 iar din 1996 suntem partener exclusiv Controls
                        cu intentia declarata de a fi mai mult decat furnizorul dumneavoastra de
                        echipamente si aparate de laborator pentru testarea materialelor de
                        constructii, ne dorim sa fim partenerul de incredere mereu atent la
                        necesitatile dumeavoastra. De aceea pe langa gama completa de aparatura va
                        punem la dispozitie intregul nostru suport in alegerea si configurarea
                        echipamentelor, livrarea, punerea in functiune, instruirea operatorilor,
                        service in garantie si postgarantie precum si servicii de modernizare si
                        reparare a vechilor dumneavoastra aparate. Oferta noastra acopera
                        urmatoarele domenii: agregate, geotehnica, cimenturi, betoane, bitum si
                        mixtura asflatica, otel, materiale plastice si cauciuc, testari
                        nedistructive.
                    </p>
                </main>
            </Layout>
        )
    }
}

export default NotFoundPage
